/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { connect } from 'react-redux';

import {
    mapStateToProps,
    ProductContainer as SourceProductContainer,
} from 'SourceComponent/Product/Product.container';
import { showNotification } from 'Store/Notification/Notification.action';
import { NotificationType } from 'Store/Notification/Notification.type';
import fromCache from 'Util/Cache/Cache';
import {
    getMaxQuantity,
    getMinQuantity,
    getName,
    getPrice,
    getProductInStock,
} from 'Util/Product/Extract';

import CartDispatcher from '../../store/Cart/Cart.dispatcher';

export {
    mapStateToProps,
    CartDispatcher,
};

/** @namespace Satisfly/Component/Product/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    addProductToCart: async (options) => CartDispatcher.addProductToCart(dispatch, options),
    showError: (message) => dispatch(showNotification(NotificationType.ERROR, message)),
});
/**
 * Abstract Product class used to hold shared functionality
 * between ProductDetails & ProductCard
 * @class ProductContainer
 * @namespace Satisfly/Component/Product/Container */
export class ProductContainer extends SourceProductContainer {
    getOmnibusPrice() {
        const { attributes: { lowest_price_in_period = null } = {} } = this.getActiveProduct();

        if (lowest_price_in_period) {
            const { attribute_value } = lowest_price_in_period;

            return attribute_value;
        }

        return false;
    }

    containerProps() {
        const {
            quantity,
            parameters,
            adjustedPrice,
            unselectedOptions,
            addToCartTriggeredWithError,
        } = this.state;
        const {
            product,
            product: { options = [] } = {},
            configFormRef,
            device,
            isWishlistEnabled,
        } = this.props;

        const activeProduct = this.getActiveProduct();
        const magentoProduct = this.getMagentoProduct();
        const {
            price_range: priceRange = {},
            dynamic_price: dynamicPrice = false,
            type_id: type,
            stock_item: {
                qty_increments = 1,
            } = {},
        } = activeProduct || {};

        const output = {
            inStock: fromCache(getProductInStock, [activeProduct, product]),
            maxQuantity: getMaxQuantity(activeProduct),
            minQuantity: getMinQuantity(activeProduct),
            productName: getName(product),
            productPrice: fromCache(getPrice, [priceRange, dynamicPrice, adjustedPrice, type, options, quantity]),
            step: qty_increments,
        };

        return {
            isWishlistEnabled,
            unselectedOptions,
            quantity,
            product,
            configFormRef,
            parameters,
            device,
            magentoProduct,
            addToCartTriggeredWithError,
            omnibusPrice: this.getOmnibusPrice(),
            ...output,
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainer);
