/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { connect } from 'react-redux';
import { Subscribe } from 'unstated';

import SharedTransitionContainer from 'Component/SharedTransition/SharedTransition.unstated';
import {
    mapDispatchToProps,
    mapStateToProps,
    ProductCardContainer as SourceProductCardContainer,
} from 'SourceComponent/ProductCard/ProductCard.container';
import { getHoverImage, getSmallImage } from 'Util/Product/Extract';

import ProductCard from './ProductCard.component';

export {
    mapDispatchToProps,
    mapStateToProps,
};

/** @namespace Satisfly/Component/ProductCard/Container */
export class ProductCardContainer extends SourceProductCardContainer {
    containerProps() {
        const {
            children,
            mix,
            layout,
            hideCompareButton,
            hideWishlistButton,
            isLoading,
            renderContent,
            product,
            isPlp,
            onLoad,
            isMobile,
            isRecentlyWidget,
            catName,
            catId,
        } = this.props;

        return {
            ...super.containerProps(),
            children,
            hideCompareButton,
            hideWishlistButton,
            isLoading,
            layout,
            mix,
            renderContent,
            isPlp,
            thumbnail: getSmallImage(this.getActiveProduct()) || getSmallImage(product),
            linkTo: this.getLinkTo(),
            hover_image: getHoverImage(this.getActiveProduct()) || getHoverImage(product),
            onLoad,
            isMobile,
            isRecentlyWidget,
            catName,
            catId,
        };
    }

    render() {
        return (
            <Subscribe to={ [SharedTransitionContainer] }>
                { ({ registerSharedElement }) => (
                    <ProductCard
                      { ...this.containerFunctions }
                      { ...this.containerProps() }
                      registerSharedElement={ registerSharedElement }
                    />
                ) }
            </Subscribe>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardContainer);
